import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import store from 'store'
// import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu } from 'antd'
import UserAction from 'redux/user/actions'
import styles from './style.module.scss'
import ChangePasswordComp from './Change-Password'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(0)

  const userActiveLogin = user?.name

  const userRole = user?.userRole

  const CompanyName = 'Thirumurugan Grand Properties'

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const changePassword = () => {
    dispatch({
      type: UserAction.SHOW_PASSWORD_MODAL,
    })
  }

  const hideChangePassword = () => {
    dispatch({
      type: UserAction.HIDE_PASSWORD_MODAL,
    })
  }

  const addCount = () => {
    // setCount(count + 1)
    setCount(count)
  }

  console.log('change password', user)
  console.log('store.get', store.get('user.profile_pic'))


  const profilePic = store.get('user.profile_pic')

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <i className="fe fe-user mr-2" />
        {userActiveLogin}
      </Menu.Item>
      {/* {renderImageContainer} */}
      <Menu.Item>
        <i className="fe fe-users mr-2" />
        {userRole === 'Super Admin' && <span>Super Admin</span>}
        {userRole === 'Vice Chairman' && <span>Vice Chairman</span>}
        {userRole === 'Director' && <span>Director</span>}
        {userRole === 'Branch Manager' && <span>Branch Manager</span>}
        {userRole === 'Sales Manager' && <span>Sales Manager</span>}
      </Menu.Item>
      {userRole && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {CompanyName}
        </Menu.Item>
      )}

      <Menu.Item>
        <a href="javascript:void(0)" onClick={changePassword}>
          <i className="fe fe-lock mr-2" />
          <FormattedMessage id="topBar.profileMenu.changePassword" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Avatar
              className={styles.avatar}
              shape="square"
              size="large"
              icon={
                <>
                  <img
                    src={`https://api.tgpcbe.com/assets/uploads/user-profile/${profilePic}`}
                    alt="avatar"
                    style={{ width: '40px', height: '40px' }}
                  />
                </>
              }
            />
          </Badge>
        </div>
      </Dropdown>
      <ChangePasswordComp
        visible={user?.displayChangePasswordModal}
        title={`Change Password for - ${user?.name}`}
        onCancel={hideChangePassword}
        loading={user?.changing}
      />
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(ProfileMenu))
